@use 'abstracts' as *;

.footer {
    @extend %spacing-y;

    %spacing-x {
        padding-left: 0;
        padding-right: 0;
    
        @each $breakpoint, $size in $spacing-x-sizes {
            @include breakpoint($breakpoint) {
                padding-left: $size;
                padding-right: $size;
            }
        }
    }
    

    &__logo {
        display: inline-block;
        margin-bottom: 3rem;
    }

    &__section-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
    }

    &__section {
        display: flex;
        flex-direction: column;
        flex: 100%;

        @include breakpoint(sm) {
            flex: 33%;
            max-width: 50%;
        }

        @include breakpoint(md) {
            flex: 1;
            max-width: 33%;
        }

        &--order {
        
            @include breakpoint(md) {
                order: 3;
            }
        }
    }

    &__social-media {
        display: flex;
        gap: 0.5rem;
    }

    &__section-label {
        color: $green-apple;
        margin-bottom: 2rem;
    }

    &__link {
        display: flex;
        align-items: baseline;
        width: fit-content;
        color: $white;
        text-decoration: none;
        margin-bottom: 0.75rem;
        transition: all $transition-time ease-in-out;

        .social-icon {
            display: inline-block;
            width: 1.25rem;
            height: auto;
            fill: $green;
            transition: all $transition-time ease-in-out;
        }

        &:hover,
        &:focus {
            color: $green-dark;

            .social-icon {
                fill: $white;
            }
        }
    }

    &__link-text {
        // Code
    }

    &__link-icon {
        display: inline-block;
        width: 0.75rem;
        height: auto;
        margin-right: 0.5rem;
    }


    &__copyrights {
        margin-top: 4rem;
        color: $white;
    }
}
