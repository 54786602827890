@use 'abstracts' as *;
@use 'sass:color';

// Background colours
.bg-white {
    background-color: $white;
}

.bg-green-light {
    background-color: $green-light;
}

.bg-green-apple-light {
    background-color: $green-apple-light;
}

.bg-coral-light {
    background-color: $coral-light
}

.bg-blue-light {
    background-color: $blue-light;
}

.bg-yellow-light {
    background-color: $yellow-light;
}

.bg-mint-light {
    background-color: $mint-light;
}

.bg-green-dark {
    background-color: $green-dark;
}

.bg-blue-dark {
    background-color: $blue-dark;
}

// SVG colours
.svg-fill-green {
    fill: $green;
}

.svg-fill-green-apple {
    fill: $green-apple;
}

.svg-fill-coral {
    fill: $coral
}

.svg-fill-blue {
    fill: $blue;
}

.svg-fill-yellow {
    fill: $yellow;
}

.svg-fill-mint {
    fill: $mint;
}