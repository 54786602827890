@use 'abstracts' as *;

html {
    font-family: $font-stack;
    font-size: 1rem;

    @include breakpoint(lg) {
        font-size: 1.25rem;
    }

    @include breakpoint(xxl) {
        // font-size: 1.75rem;
    }
}

h1, h2, h3, h4 {
    margin-top: 0;
    margin-bottom: 1rem;
}

p {
    line-height: 1.75rem;
}

a {
    // code
}

a:not([class]) {
    color: $green-dark;
    transition: all $transition-time ease-in-out;
    text-decoration: transparent;
    font-weight: bold;

    &:focus,
    &:hover {
        text-decoration: underline;
        text-decoration-color: $green-dark;
    }
}

ul, ol {
    line-height: 1.75rem
}

.seo-title {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: rgba($blue, 0.25);
    font-size: 0.75rem;
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 100px;
}