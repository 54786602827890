@use 'abstracts' as *;

.hero {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;

    // Column with text
    &__content {
        @extend %spacing-x;
        @extend %spacing-y;

        padding-top: 100px;
        grid-area: 1 / 1 / 2 / 2;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__content-inner {
        color: $white;
    }

    &__title {
        font-size: 2.25rem;
        margin-bottom: 1rem;
        max-width: 15ch;

        @include breakpoint(sm) {
            font-size: 2.5rem;
        }

        @include breakpoint(md) {
            font-size: 3rem;
        }
    }

    &__text {
        font-size: 1.125rem;
    }

    &__img {
        display: block;
        width: 100%;
        // max-height: 100vh;      // Remove this lock if thers a lot content
        height: 100%;
        object-fit: cover;

        @include breakpoint(sm) {
            height: auto;
        }

        @include breakpoint(md) {
            aspect-ratio: 16/9;
        }
    }

    // Column with image
    &__media {
        grid-area: 1 / 1 / 2 / 2;
        z-index: 1;

        position: relative;

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(180deg, rgba($blue-dark, 75%) 0%, rgba($blue-dark, 25%) 100%);
        }
    }

    // Scroll to next block button
    &__btn-scroll {
        margin-top: 2rem;
        display: block;
        position: relative;
        width: 2rem;
        height: 2rem;
        border: $btn-border-width solid $green;
        background-color: rgba($black, 10%);
        border-radius: 100%;
        cursor: pointer;
        transition: all $transition-time ease-in-out;

        &:hover,
        &:focus {
            background-color: $green;
    
            &:after {
                content: "";
                border-bottom: solid 2px $white;
                border-right: solid 2px $white;
            }
        }
    
        &:after {
            content: "";
            position: absolute;
            top: 42%;
            left: 50%;
            display: block;
            width: 10px;
            height: 10px;
            transform: translate(-50%, -50%) rotate(45deg);
            border-bottom: solid 2px $white;
            border-right: solid 2px $white;
            transition: all $transition-time ease-in-out;
        }
        
        @include breakpoint(sm) {
            position: absolute;
            z-index: 3;
    
            left: $spacing-x--xs;
            @each $breakpoint, $size in $spacing-x-sizes {
                @include breakpoint($breakpoint) {
                    left: $size;
                }
            }
    
            
            bottom: $spacing-x--xs;
            @each $breakpoint, $size in $spacing-x-sizes {
                @include breakpoint($breakpoint) {
                    bottom: $size;
                }
            }
        }
        
    }
}



