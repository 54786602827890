@use 'abstracts' as *;

.block-faq {
    @extend %spacing-y;
    
    display: flex;
    flex-direction: column;
    
    @include breakpoint(md) {
        align-items: center;
        text-align: center;
    }

    &__header {
        margin-bottom: 2rem;
    }

    &__heading {
        font-size: 2rem;
        margin-bottom: 1rem;
        margin-top: 0;
    }
}

/* accordion styles */
.accordion {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1.25rem;
    border-radius: 10px;
    border: 2px solid $black;
    text-align: start;
    transition: all $transition-time ease-in-out;

    @include breakpoint(md) {
        max-width: 80ch;
    }

    &:hover,
    &:focus {
        border-color: $green-dark;

        .accordion__icon {
            color: $green-dark;
        }
    }
  
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        transition: background-color $transition-time ease-in-out;
        cursor: pointer;
    }

    &__label {
        flex: 1;
    }

    &__icon {
        transition: transform 0.3s ease;
        font-size: 1.5rem;
        color: $black;
        transition: all $transition-time ease-in-out;
    }

    &__content {
        height: auto;
        max-height: 0;
        opacity: 0;
        transition: max-height 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.2s;
    }

    &__text {
        padding: 0 1rem 1rem 1rem;

        > * {
            margin-top: 0;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.is-open {
        border-color: $green-dark;

        .accordion__content {
            max-height: 600px;
            opacity: 1;
        }

        .accordion__icon {
            transform: rotate(45deg);
            color: $green-dark;
        }
    }
}
  