@use 'abstracts' as *;

.btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    transition: all $transition-time ease-in-out;
    box-sizing: border-box;
    
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0px 5px 10px rgba($black, 20%);

    &--fill {
        border-width: $btn-border-width;
        border-style: solid;
        border-color: $green-dark;
        background: $green-dark;
        color: $white;
        
    
        &:hover,
        &:focus {
            color: $white;
            box-shadow: 0px 5px 10px rgba($black, 0%);
        }
    }

    &--outline {
        border-width: $btn-border-width;
        border-style: solid;
        border-color: $green-dark;
        color: $black;
        background: transparent;
        font-weight: bold;
    
        &:hover,
        &:focus {
            background-color: $green-light;
            box-shadow: 0px 5px 10px rgba($black, 0%);
        }
    }

    &--wide {
        padding: 0.5rem 2rem;
    }
}

