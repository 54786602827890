@use 'abstracts' as *;

.block-media {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
        flex-direction: row-reverse
    }

    &__content-wrapper {
        @extend %spacing-x;
        @extend %spacing-y;

        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $white;

        @include breakpoint(md) {
            width: 40%;
        }
    }

    &__heading {
        font-size: 1.6rem;
    }

    &__text {
        // Code
    }

    &__media-wrapper {

        @include breakpoint(md) {
            width: 60%;
        }
    }

    &__image-wrapper {
        @include breakpoint(md) {
            position: relative;
            padding-top: 66%;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;

        @include breakpoint(md) {
            position: absolute;
            bottom: 0;
            left: 0; 
        }
    }
}