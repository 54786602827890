@use 'abstracts' as *;
@use 'sass:color';

.cards {
    padding-top: 2rem;
}

.card-person {
    @extend %spacing-y;
    @extend %spacing-x;
    
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 2rem;

    @include breakpoint(sm) {
        flex-direction: row;
    }

    &--reverse {
        @include breakpoint(sm) {
            flex-direction: row-reverse;
            align-items: center
        }
    }

    &--no-spacing {
        padding: 0;
        margin-bottom: 0;
    }

    /* ----- Content Column - text ----- */
    &__content {
        // @extend %spacing-x;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &--align-center {
            justify-content: center;
        }

        @include breakpoint(sm) {
            width: 50%;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
    }

    &__text {
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin-top: 0;
        margin-bottom: 1.25rem;
    }

    &__author {
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.5rem;
    }

    &__location {
        font-size: 1.125rem;
    }

    &__person-image {
        display: inline-block;
        width: 10rem;
        height: 10rem;
        border-radius: 77% 23% 36% 64% / 36% 40% 60% 64%; // Blob shape
        object-fit: cover;
        margin-bottom: 2rem;
    }

    /* ----- Media Column - image ----- */
    &__media {
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(sm) {
            width: 50%;
        }
    }

    &__image-wrapper {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        width: 18.75rem;
        height: 18.75rem;

        @include breakpoint(md) {
            width: 22rem;
            height: 22rem;
        }

        @include breakpoint(lg) {
            width: 24rem;
            height: 24rem;
        }
    }

    &__blob-underlay {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 80%;
        height: 80%;
        transition: all 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__tooltip {
        text-align: center;
        border-radius: 20px;
        background-color: $white;

        padding: 0.5rem 1rem;
        position: absolute;
        right: 25%;
        bottom: -30px;
        text-align: center;
        width: 15rem;
        box-shadow: 0px 5px 10px #17333d26;
        font-size: 1rem;

        width: 15rem;
    }

    &__tooltip-icon {
        position: relative;
        margin: 0 0 0.25rem 0;
        display: inline-block;
        width: 3rem;
        height: 3rem;
        border: 2px solid black;
        border-radius: 100%;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            border: solid 6px $green;
            border-radius: 100%;
            transition: all 0.3s ease-in-out;
        }


        // chage this to animate class. this should be triggered when line reaches this box
        &:hover {
            &:after {

                width: 0;
                height: 0;
                border-width: 0;
                
            }
        }
    }

    &__tooltip-text {
        margin: 0 0 0.25rem 0;
    }

    &__tooltip-prominent-text {
        font-weight: bold;
        margin: 0;
    }
}

.shift-top {
    position: relative;
    top: 0;
    transform: translateY(-42%);


    @include breakpoint(sm) {
        top: -100%;
        transform: translateY(42%);
    }
}

// first card media grid
.d-grid {
    @include breakpoint(sm-max) {
        display: grid;
        grid-template-rows: 150px;
    }
}