@use 'abstracts/variables' as *;

@mixin breakpoint($media) {

	// Max, min and specific breakpoint media queries

	@if $media == xs-max {
		@media only screen and (max-width: ($xs - 1)) { @content }
	}
	@else if $media == sm-max {
		@media only screen and (max-width: ($sm - 1)) { @content }
	}
	@else if $media == md-max {
		@media only screen and (max-width: ($md - 1)) { @content }
	}
	@else if $media == lg-max {
		@media only screen and (max-width: ($lg - 1)) { @content }
	}
	@else if $media == mx-max {
		@media only screen and (max-width: ($mx - 1)) { @content }
	}
	@else if $media == xxl-max {
		@media only screen and (max-width: ($xxl - 1)) { @content }
	}

	@if $media == xs {
		@media only screen and (min-width: $xs) { @content }
	}
	@else if $media == sm {
		@media only screen and (min-width: $sm) { @content }
	}
	@else if $media == md {
		@media only screen and (min-width: $md) { @content }
	}
	@else if $media == lg {
		@media only screen and (min-width: $lg) { @content }
	}
	@else if $media == mx {
		@media only screen and (min-width: $mx) { @content }
	}
	@else if $media == xxl {
		@media only screen and (min-width: $xxl) { @content }
	}
	

	@if $media == xs-only {
		@media only screen and (min-width: $xs) and (max-width: ($sm - 1)) { @content }
	}
	@else if $media == sm-only {
		@media only screen and (min-width: $sm) and (max-width: ($md - 1)) { @content }
	}
	@else if $media == md-only {
		@media only screen and (min-width: $md) and (max-width: ($lg - 1)) { @content }
	}
	@else if $media == lg-only {
		@media only screen and (min-width: $lg) and (max-width: ($mx - 1)) { @content }
	}
	@else if $media == mx-only {
		@media only screen and (min-width: $mx) { @content }
	}
	@else if $media == xxl-only {
		@media only screen and (min-width: $xxl) { @content }
	}
};



/* ---------- Content spacing - Creates pading left/right on all breakpoints ---------- */
// Spacing x axis
%spacing-x {
	padding-left: $spacing-x--xs;
	padding-right: $spacing-x--xs;

	@each $breakpoint, $size in $spacing-x-sizes {
        @include breakpoint($breakpoint) {
            padding-left: $size;
            padding-right: $size;
        }
	}
}

// Spacing y axis
%spacing-y {
	padding-top: $spacing-x--xs;
	padding-bottom: $spacing-x--xs;

	@each $breakpoint, $size in $spacing-y-sizes {
        @include breakpoint($breakpoint) {
            padding-top: $size;
            padding-bottom: $size;
        }
	}
}

