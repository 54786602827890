@use 'abstracts' as *;

.key-benefits {
    // @extend %spacing-x;
    @extend %spacing-y;

    %spacing-x {
        padding-left: 0;
        padding-right: 0;
    
        @each $breakpoint, $size in $spacing-x-sizes {
            @include breakpoint($breakpoint) {
                padding-left: $size;
                padding-right: $size;
            }
        }
    }
    
    position: relative;

    &__heading {
        line-height: 1.5;

        &--short {
            max-width: 30ch;
        }
    }

    &__intro {
        line-height: 1.75rem;

        &--short {
            max-width: 45ch;
        }
    }

    &__details {
        font-size: 0.75rem;
        color: $grey;
        padding-bottom: 10rem;
    }

    br {
        display: none;

        @include breakpoint(xs) {
            display: inline-block;
        }
    }
}

.box-icon-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.625rem;

    @include breakpoint(xs) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(md) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.grid-numbers {
    margin-top: 2rem;
    display: grid;
    gap: 2.625rem;
    grid-template-columns:  repeat( auto-fit, minmax(300px, 1fr) );

    @include breakpoint(sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.box-icon {

    > *:not(:last-child) {
        margin-bottom: 1rem;
    }

    &__icon {
        display: block;
        width: 100px;
        height: 100px;
    }

    &__title {
        font-weight: bold;
        font-size: 1.125rem;
        margin: 0 0 1rem 0;
        
        &--short {
            max-width: 13ch;
        }
    }   

    &__text {
        font-size: 1rem;
        max-width: 40ch;
    }

    &__number {
        font-size: 5rem;
        font-weight: bold;
    }
}