@use 'abstracts' as *;

// $max-content-width: 96rem; // 1920px if mx is 1.25rem
$max-content-width: 1920px;

.layout {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &__inner {
        display: block;
        margin: 0 auto;
    }

    &--streached {

        .layout__inner {
            width: calc(100% - ($block-spacing-x--xs*2));
            max-width: $max-content-width;

            @each $breakpoint, $size in $block-spacing-x-sizes {
                @include breakpoint($breakpoint) {
                    width: calc(100% - ($size*2));
                }
            }
        }
    }

    &--contained {
        border-radius: 20px;
        width: calc(100% - ($block-spacing-x--xs*2));
        max-width: $max-content-width;

        @each $breakpoint, $size in $block-spacing-x-sizes {
            @include breakpoint($breakpoint) {
                width: calc(100% - ($size*2));
                max-width: $max-content-width;
            }
        }

        margin-top: $block-spacing-y--xs;
        margin-bottom: $block-spacing-y--xs;

        @each $breakpoint, $size in $block-spacing-y-sizes {
            @include breakpoint($breakpoint) {
                margin-top: $size;
                margin-bottom: $size;
            }
        }
    }
}

main {
    // code
}

