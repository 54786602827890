@use 'abstracts' as *;

.simple-page {
    @extend %spacing-y;

    display: block;
    margin: 0 auto;
}

.back-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.back-btn {
    color: $black;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-decoration: none;
    font-weight: bold;

    &:hover,
    &:focus {

        .back-btn__icon {
            left: 5px;
        }
    }

    &__text {
        font-size: 1rem;
        margin-right: 0.5rem;
    }

    &__icon {
        position: relative;
        left: 0;
        display: flex;
        align-items: center;
        transition: all $transition-time ease-in-out;

        img {
            width: 0.55rem;
            height: auto;
        }
    }
}

.doc-version {
    color: $grey;
}