@use 'abstracts' as *;

.header {
    @extend %spacing-x;

    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: top $transition-time ease-in-out;

    &__logo-wrapper {
        z-index: 9;
        margin-left: 0;
        margin-right: auto;
    }

    &__logo {
        display: block;
    }

    &.nav-is-open {
        background-color: $coral;
    }
}

// Nav generic styles
.nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    &__link {
        text-decoration: none;
        color: $white;
        font-size: 0.75rem;
        transition: all $transition-time ease-in-out;

        @include breakpoint(xs) {
            font-size: 1rem;
        }

        &--fake {
            display: inline-block;
            padding: 0.5rem 1rem;
            border-radius: 100px;
            transition: all $transition-time ease-in-out;
            box-sizing: border-box;
            
            text-decoration: none;
            box-shadow: 0px 5px 10px rgba($black, 20%);
            border-width: $btn-border-width;
            border-style: solid;
            border-color: $green-dark;
            background: $green-dark;
            color: $white;

            &:hover, 
            &:focus {
                color: $white;
            }
        }

        &:hover, 
        &:focus {
            color: darken($white, 10%);
        }

        &--button {
            padding: 0.75rem 1.25rem;
            border-radius: 100px;
            border-style: solid;
            border-width: $btn-border-width;
            border-color: $green;
            background-color: $green;
            color: $white;
            transition: all $transition-time ease-in-out;

            &:hover, 
            &:focus {
                background-color: $white;
                color: $green;
            }
        }
    }
}

// Mobile
@include breakpoint(md-max) {

    .nav {
        --header-height: 100px;
        
        padding-top: var(--header-height);
        position: absolute;
        top: -100vh;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-color: $coral;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: top $transition-time ease-in-out;
        
        &.nav-is-open {
            top: 0;
        }
    }

    .menu-btn {
        width: 60px;
        height: 60px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all $transition-time ease-in-out;

        &__burger {
            width: 40px;
            height: 4px;
            background: $green;
            border-radius: 5px;
            transition: all $transition-time ease-in-out;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 40px;
                height: 4px;
                background: $green;
                border-radius: 5px;
                transition: all $transition-time ease-in-out;
            }

            &::before {
                transform: translateY(-12px);
            }

            &::after {
                transform: translateY(12px);
            }
        }

        // Open state
        &.nav-is-open {
            .menu-btn__burger {
                transform: translateX(-50px);
                background: transparent;

                &::before {
                    transform: rotate(45deg) translate(35px, -35px);
                    background: $white;
                }

                &::after {
                    transform: rotate(-45deg) translate(35px, 35px);
                    background: $white;
                }            
            }
        }
    }
}

// Add this class to main wrapper for header so it overlaps with hero
.nav-overlap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9; // dont go with crazy numbers as theres a popup form on this site
}