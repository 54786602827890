/* ----- Colours ----- */
// Generic
$white: #fff;
$black: #000;
$grey: #898080;

// Brand
$green: #12CC59;
   $green-light:rgba($green, 0.25);

$green-apple: #d4f236;
   $green-apple-light:rgba($green-apple, 0.25);

$coral: #FF9670;
   $coral-light:rgba($coral, 0.25);

$blue: #38FAFF;
   $blue-light:rgba($blue, 0.25);

$yellow: #FFCC59;
   $yellow-light:rgba($yellow, 0.25);

$mint: #00EDA6;
   $mint-light:rgba($mint, 0.25);

$green-dark: #089e69;
$blue-dark: #17333d;

// Animations
$transition-time: 0.3s;

// UI 
$btn-border-width: 2px;

/* ----- Fonts ----- */
$font-stack: 'DM Sans', sans-serif;

/* ----- Breakpoints ----- */
$xs: 480px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$mx: 1681px;
$xxl: 1920px;

/* ----- Block/Section Spacing ----- */
// outer spacing for wrapper that has content
$block-spacing-x--xs: 2rem;
$block-spacing-x--sm: 2.5vw;
$block-spacing-x--md: 5vw;
$block-spacing-x--lg: 7.5vw;
$block-spacing-x--mx: 7.5vw;
$block-spacing-x--xxl: 7.5vw;

   $block-spacing-x-sizes: (
      "xs-only": $block-spacing-x--xs,
      "sm-only": $block-spacing-x--sm,
      "md-only": $block-spacing-x--md,
      "lg-only": $block-spacing-x--lg,
      "mx-only": $block-spacing-x--mx,
      "xxl-only": $block-spacing-x--xxl
   );

   // Consider removing block-spacing-y ------- CHECK LATER IF STILL NEEDED OR NOT
$block-spacing-y--xs: 2rem;
$block-spacing-y--sm: 2.5vw;
$block-spacing-y--md: 5vw;
$block-spacing-y--lg: 5vw;
$block-spacing-y--mx: 5vw;

   $block-spacing-y-sizes: (
      "xs-only": $block-spacing-y--xs,
      "sm-only": $block-spacing-y--sm,
      "md-only": $block-spacing-y--md,
      "lg-only": $block-spacing-y--lg,
      "mx-only": $block-spacing-y--mx
   );

/* ----- Content Spacing ----- */
// Spacing inside section/block
$spacing-x--xs: 2rem;
$spacing-x--sm: 4vw;
$spacing-x--md: 5vw;
$spacing-x--lg: 5vw;
$spacing-x--mx: 5vw;
$spacing-x--xxl: 5vw;

   $spacing-x-sizes: (
      "xs-only": $spacing-x--xs,
      "sm-only": $spacing-x--sm,
      "md-only": $spacing-x--md,
      "lg-only": $spacing-x--lg,
      "mx-only": $spacing-x--mx,
      "xxl-only": $spacing-x--xxl,
   );

$spacing-y--xs: 2rem;
$spacing-y--sm: 3vw;
$spacing-y--md: 4vw;
$spacing-y--lg: 4vw;
$spacing-y--mx: 4vw;
$spacing-y--xxl: 4vw;

   $spacing-y-sizes: (
      "xs-only": $spacing-y--xs,
      "sm-only": $spacing-y--sm,
      "md-only": $spacing-y--md,
      "lg-only": $spacing-y--lg,
      "mx-only": $spacing-y--mx,
      "xxl-only": $spacing-y--xxl,
   );