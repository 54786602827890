@use 'abstracts' as *;

.popup{
    position: fixed;
    top: 0;
    right: -110%;
    width: 100%;
    height: 100%;
    z-index: 99;

    transition: all $transition-time ease-in-out;

    &.is-open {
        right: 0;
    }

    &__bg-layer {

        @extend %spacing-x;
        padding-top: 1rem;
        padding-bottom: 1rem;

        height: 100%;
    }

    &__logo {
        max-width: 120px;
        display: block;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__blob {
        bottom: 50%;
        display: block;
        height: 450px;
        position: absolute;
        right: 50%;
        transform: translate(45%, 50%);
        width: 450px;
        z-index: -1;
        opacity: 0.5;
    }

    &__form-wrapper {
        max-width: 60ch;
    }

    &__btn-close-wrapper {
        display: flex;
        justify-content: space-between;
        padding-right: 0.5rem;

        h2 {
            font-size: 1.25rem;
        }
    }

    &__btn-close {
        display: inline-flex;
        padding: 0.125rem;
        text-decoration: none;
        transform: rotate(45deg);

        svg {
            path {
                fill: $black;
                transition: all $transition-time ease-in-out;
            }
        }

        &:hover,
        &:focus {
            svg {
                path {
                    fill: $green-dark;
                }
            }
        }
    }
}

body {
    &.no-scroll {
        overflow: hidden;
    }
}